<template>
  <div>
    <v-row >
<!--      Remove the dialogs from the component.-->
      <v-dialog v-model="dialogDelete" max-width="380px"  transition="dialog-top-transition">
        <v-card class="pa-7">
          <v-card-title class="d-flex justify-center align-center">
            <v-row  dense>
              <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background">
                  <v-icon dense color="main_red" style="font-size: 30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
              </v-col>
              <v-col cols="12">
                <span>{{ $t('deleteQuestion') }}</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
          <v-card-actions class="mb-2">
            <v-row dense>
              <v-col cols="12">
                <v-btn block depressed color="red" class="text-color-white" @click="deleteOrganizationConfirm">{{ $t('deleteField') }}</v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn  outlined depressed color="main_black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
          <v-data-table
              v-if="allMedicalInstitutions"
              class="pa-1 mt-6 medical-staff-data-table"
              :loading="loading"
              :no-data-text="$t('no-data-available')"
              :loading-text="$t('loading')"
              :headers="headers"
              :headers-length="headers.length"
              :search="appBarSearch"
              :items="allMedicalInstitutions"
              sort-by="id"
              item-key="id"
              fixed-header
              dense
              @click:row="medicalInstitutionDetails"
              :height="pageHeight(150)"
              :single-expand="true"
              :expanded.sync="expanded"
              :footer-props="footerProps"
              :items-per-page="-1"
          >
            <template v-slot:item="{ item }">
              <tr @click="medicalInstitutionDetailsV2(item)">
                <td class="table-font-size pa-0 ma-0">
                  <div class="d-flex align-center">
                    <v-img :src="HospitalBlack" class="ml-2" max-height="21" max-width="20"></v-img>
                   <span class="ml-2">{{ item.institutionName }}</span>
                  </div>

                </td>
                <td class="table-font-size">
                  {{ item.description }}
                </td>
                <td class="table-font-size">
                    {{ item.contactPerson }}
                </td>
                <td class="table-font-size">
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-1" size="15">mdi-email</v-icon>
                   <span>{{ item.contactEmail }}</span>
                  </div>

                </td>
                <td class="table-font-size">
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-1" size="15">mdi-phone</v-icon>
                   {{ item.contactPhone }}
                  </div>
                </td>
                <td class="text-right">
                  <v-menu
                      left
                      bottom
                      class="py-0"
                      offset-y
                      transition="slide-x-transition"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-btn
                          icon
                          color="main_black"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <span class="mdi mdi-24px mdi-dots-horizontal"></span>
                      </v-btn>
                    </template >

                    <v-list class="px-0" dense >
                      <v-list-item class="py-0 my-0" link dense @click="handleActionView(item)">
                        <v-list-item-icon class="mx-1 ">
                          <v-icon class="icon-img mdi-18px" color="main_black">mdi-eye</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="font-bold font-size14">{{$t("view")}}</v-list-item-content>
                      </v-list-item>
                      <v-divider v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER' || roleName === 'ORGANIZATION_ADMIN'"/>
                      <v-list-item v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER' || roleName === 'ORGANIZATION_ADMIN'" link dense @click="generateCSV(item)">
                        <v-list-item-icon class="mx-1 ">
                          <v-icon class="icon-img mdi-18px" color="main_black">mdi-eye</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="font-bold font-size14">CSV</v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="roleName === 'SYSTEM_ADMIN'" link dense @click="deleteOrganizationById(item)">
                        <v-list-item-icon class="mx-1 ">
                          <v-icon color="red">mdi-trash-can-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="font-bold font-size14">{{$t("delete")}}</v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
import { organizationsBlack, hospitalBlack } from '@/assets';

export default {
  components: {
  },
  data: () => ({
    footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
    HospitalBlack: hospitalBlack,
    isOpen: {},
    loading: true,
    headerClass: 'color-header',
    search: '',
    expanded: [],
    dialogDelete: false,
    organizationIdToDelete: -1,
  }),
  computed: {
    organizationsBlack () {
      return organizationsBlack;
    },
    ...mapGetters({
      roleName: 'authentication/getRole',
      pageHeight: 'pageHeight',
      appBarSearch: 'filterbar/getSearch',
    }),
    ...mapState({
      allMedicalInstitutions: (state) => state.medicalInstitutions.medicalInstitutions,
      generatedCsvFile: (state) => state.billings.generatedCsvFile,
    }),
    headers () {
      var headers = [];
      headers = [
        {
          text: this.$t('name'),
          value: 'institutionName',
          class: this.headerClass,
        },
        {
          text: this.$t('description'),
          value: 'description',
          class: this.headerClass,
        },
        {
          text: this.$t('contact'),
          value: 'contactPerson',
          class: this.headerClass,
        },
        {
          text: this.$t('contact-email'),
          value: 'contactEmail',
          class: this.headerClass,
        },
        {
          text: this.$t('contact-phone'),
          value: 'contactPhone',
          class: this.headerClass,
        },
        { text: this.$t('actions'), value: 'status', class: this.headerClass, sortable: false, align: 'end' },
      ];
      return headers;
    },
  },
  async mounted () {
    await this.getMedicalInstitutions();
  },
  methods: {
    async getMedicalInstitutions () {
      this.loading = true;
      await this.$store
          .dispatch('medicalInstitutions/getMedicalInstitutions')
          .then(() => {
            // this.medicalInstitutions = this.medicalInstitutionsFromRepo;
            this.loading = false;
          });
    },
    async editMedicalInstitution (item) {
      await this.$store.commit('medicalInstitutions/SELECTED_MEDICAL_INSTITUTION', item);
      this.$store.commit('SET_EDIT_DETAILS_DRAWER', true);
    },
    medicalInstitutionDetails (item) {
      this.editMedicalInstitution(item);
    },
    medicalInstitutionDetailsV2 (item) {
      this.editMedicalInstitution(item);
      this.$set(this.isOpen, item.id, !this.isOpen[item.id]);
    },
    handleActionView (item) {
      this.editMedicalInstitution(item);
    },
    async generateCSV (item) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const data = { organizationId: item.id, month: currentMonth, year: currentYear };
      await this.$store.dispatch('billings/generateCsvOrganizationPatientsForMonth', data)
          .then(res => {
            showSuccessAlert(this.$t('success-operation'));
            const url = window.URL.createObjectURL(new Blob([this.generatedCsvFile.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'org-billings-csv.csv');
            document.body.appendChild(link);
            link.click();
          });
    },
    async deleteOrganizationById (item) {
      this.dialogDelete = true;
      this.organizationIdToDelete = item.id;
    },
    async deleteOrganizationConfirm () {
      await this.$store.dispatch('medicalInstitutions/deleteMedicalInstitution', this.organizationIdToDelete)
          .then(res => {
            if (res.data.resFlag) {
              showSuccessAlert(this.$t('success-operation'));

              this.getMedicalInstitutions();
              this.closeDeleteDialog();
            } else {
              showErrorAlert(this.$t('failed-operation'));
            }
          });
    },
    closeDeleteDialog () {
      this.dialogDelete = false;
      this.organizationIdToDelete = -1;
    },
  },
};
</script>
